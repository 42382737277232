export const URLS = {
	ANAGRAPHIC: '/api/v1/anagraphic',
	CONFIG: {
		PRIVATE: '/api/v1/config',
		PUBLIC: '/public/config'
	},
	CONSOLE_PYTHON: '/api/v1/console/execute',
	FILE: '/api/v1/files',
	FUNCTION_DEFINITION: '/api/v1/functionDefinition',
	FOLDER: '/api/v1/folders',
	SMS_INVOICES: '/public/invoices',
	LOGO_LOGIN: '/public/logoLogin',
	LOGS: 'api/v1/logs/logs',
	PROFILES: {
		PROFILES: '/api/v1/profiles',
		ALL_NOTIFICATIONS: '/api/v1/profiles/allnotifications',
		NOTIFICATIONS: '/api/v1/profiles/notifications',
		ME: '/api/v1/profiles/me',
		LOGIN: '/api/v1/profile/login',
		RESET: '/api/v1/profile/reset',
		REGISTRATION: '/api/v1/portal/profiles/registration',
		REFRESH: '/api/v1/profile/refresh',
		SYNC_LDAP: '/api/v1/profiles/sync'
	},
	SECURITY: {
		DESCRIBE: '/api/v1/security/describe',
	},
	TENANTS: '/api/v1/tenants',
	VALIDATE_TOKEN: '/public/validatetoken',
	MARKERS :'/api/v1/markers',
	DEVICE: {
		GET:'/api/v1/devices/devices',
		DETAIL:'/api/v1/devices/devices',
		REGISTRATION:'/api/v1/devices/registration',
		ELECTRIC:'/api/v1/devices/overview/electric',
		ELECTRIC_DETAIL:'/api/v1/devices/overview/electric/detail',
	},
	DASHBOARD: '/api/v1/dashboard',
	FLEET:'/api/v1/fleet',
	ALARM:'/api/v1/alarm',
	ALARM_HB:'/api/v1/alarmHB',
	EVENT:'/api/v1/event',
	DIAGNOSTIC:'/api/v1/diagnostic',
	DIAGNOSTIC_HB:'/api/v1/diagnosticHB',
	DIAGNOSTIC_RAW:'/api/v1/diagnosticRAW',
	DIAGNOSTIC_RUNTIME_HB:'/api/v1/diagnosticRuntimeHB',
	DIAGNOSTIC_CHART_HB:'/api/v1/diagnosticChartHB',
	DIAGNOSTIC_CHART_RAW:'/api/v1/diagnosticChartRAW',
	TRIP:'/api/v1/trip',
	TRIP_HB:'/api/v1/tripHB',
	RECHARGES:'/api/v1/charge',
	RECHARGES_HB:'/api/v1/chargeHB',
	RECHARGES_SUMMARY_HB:'/api/v1/chargeSummaryHB',
	MAINTENANCES:'/api/v2/maintenances',
	MAINTENANCE:'/api/v2/maintenance/',
	WARRANTIES:'/api/v1/devices/overview/warranties',
	ASSETS_POST:{
		GET:'/api/v2/assets',
		POST:'/api/v2/assets/',
		PORDERS:'/api/v2/productionOrders',
		NOTES:'/notes/',
		CHECKLIST:'/checklist',
		COMPONENTS:'/components',
		BPARTNER:'/api/v2/bpartner',
		DOCUMENTS:'/documents',
		ACTIONS:'/actions'
	},
	SALES_ORDER:{
		GET:'/api/v2/saleorder/',
	},
	SPARE_PARTS:{
		GET:'/api/v2/sparepart/',
	},
	WARRANTY:{
		GET_LIST:'/api/v2/warranty/asset/',
		GET_LIST_COMPONENTS:'/api/v2/warranty/elements/',
		GET:'/api/v2/warranty/',
		POST:'/api/v2/warranty/',
	},
	ITEMS:{
		GET:'/api/v2/items',
		BOMS:'/api/v2/items/bom',
		VIN:'/api/v2/items/vin'
	},
	VIN:{
		GET:'/api/v2/vin'
	},
	LOCALTASKS: '/api/v1/localtasks',
	PORTAL_LOCALTASKS: '/api/v1/portal/localtasks',
	TASKS: '/api/v1/tasks',
	TASKS_COLLECTIONS: '/api/v1/taskcollections',
	WORKFLOW: '/api/v1/workflows',
	WORKFLOW_TYPE: '/api/v1/workflowType',
	STORYBOARDS: '/api/v1/storyboards',
	BPARTNERS: '/api/v1/crm/bpartners',
	BPARTNERS_SEARCH_BY_FISCAL_CODE: '/api/v1/crm/bpartners/findByFiscalCode',
	BPARTNERS_SEARCH_BY_VAT_CODE: '/api/v1/crm/bpartners/findByVatCode',
	BPARTNERS_SEARCH_BY_FISCAL_CODE_AND_VAT: '/api/v1/crm/bpartners/findByFiscalCodeAndVat',
	BPARTNERS_SEARCH_BY_DISTRIBUTOR_CODE: '/api/v1/crm/bpartners/findDistributorByCode',
	DOCUMENTS: '/api/v1/documents',
	AGENTS: '/api/v1/sales/agents',
	WORKSPACES: '/api/v1/workspaces',
	WORKSPACES_TYPOLOGY: '/api/v1/workspacesType',
	MAIL: {
		PORTAL:{
			MAILBOX: 'api/v1/portal/findmailboxes',
			MAILS: 'api/v1/portal/findmails',
			TAGMAILS: 'api/v1/portal/tags/findmails',
			RESTORE: 'api/v1/portal/restoremail',
			EMPTYTRASH: 'api/v1/portal/emptytrash',
			ARCHIVE_SELECTED: 'api/v1/portal/archiveselected',
			SEND: 'api/v1/portal/mails/sendEportal'
		},
		FOLDER: 'api/v1/mails/folders',
		MAILS: 'api/v1/mails',
		SEND: 'api/v1/mails/send',
		MAILBOX: 'api/v1/mailboxes',
		TAGS: 'api/v1/mailtags'
	},
	REPOS:{
		GET:'/api/v1/repo',
		POST:'/api/v1/repo'
	},
	REPORT_DEFINITIONS: '/api/v1/reports/definitions',
	REPORT_PARAMETERS: '/api/v1/reports/parameters',
	MESSAGE_TEMPLATES: '/api/v1/messagetemplates',
	SETUP: '/api/v1/setup',
	WORKFLOW_RETRY: '/api/v1/wengine/workflow/retry',
	WORKFLOW_RETRY_ALL: '/api/v1/wengine/workflow/retry/all',
	GROUP: '/api/v1/group',
	MEASURE: '/api/v1/measure',
	MEASURE_GRP: '/api/v1/measures/group',
	ASSOCIATION: '/api/v1/group/measure/association',
	DASHBOARD_TEMPLATE:'/api/v1/dashboardTemplate',
	MANAGEMENT: {
		ASSETS: '/api/v1/management/assets'
	},
	DASHBOARD_REPORT: '/api/v1/dashboardReport',
	USERS: {
		GET: '/api/v2/users',
		DELETE: '/api/v2/user/',
		POST:  '/api/v2/user',
	},
	TICKET_WARRANTY: {
          LIST: '/api/v2/tickets',
		  GET: '/api/v2/ticket/',
		  POST: '/api/v2/ticket',
		  GET_XLS: '/api/v2/ticket/excel/'
	},
	ASSET_TYPE:'/api/v1/assetType',
	USER_ASSOCIATION: '/api/v1/asset/user/association',
	FLEET_ASSOCIATION: '/api/v1/fleet/association',
	ASSETS:{
        GET:'/api/v1/assets',
    }
};
