/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import DesktopGuard from './components/Guards/DesktopGuard';
import AuthGuard from './components/Guards/AuthGuard';
import GuestGuard from './components/Guards/GuestGuard';
import AuthLayout from './submodules/commons-auth';

const routesConfig = [
	{
		exact: true,
		path: '/',
		component: () => <Redirect to='/app' />
	},
	{
		exact: true,
		path: '/404',
		component: lazy(() => import('src/views/pages/Error404View'))
	},

	{
		exact: true,
		path: '/500',
		component: lazy(() => import('src/views/pages/Error500'))
	}
	,
	{
		path: '/auth',
		guard: GuestGuard,
		layout: AuthLayout,
		routes: [
			{
				exact: true,
				path: '/auth/login',
				component: lazy(() => import('src/submodules/commons-auth/Login'))
			},
			{
				component: () => <Redirect to="/auth/login" />
			}
		]
	}
	,{
		guard: AuthGuard,
		routes: [
			{
				path: '/app',
				guard: DesktopGuard,
				layout: DashboardLayout,
				routes: [
					{
						exact: true,
						path: [
							'/app',
							'/app/management/groups'
						],
						component: lazy(() => import('src/views/management/groups/List'))
					},
					{
						exact: true,
						path: [
							'/app/management/firmware'
						],
						component: lazy(() => import('src/views/management/firmwareRepository/List'))
					},
					{
						exact: true,
						path: [
							'/app/management/firmware/create'
						],
						component: lazy(() => import('src/views/management/firmwareRepository/Create'))
					},
					{
						exact: true,
						path: [
							'/app/management/firmware/edit/:fwId',
						],
						component: lazy(() => import('src/views/management/firmwareRepository/Edit'))
					},
					{
						exact: true,
						path: [
							'/app/management/config'
						],
						component: lazy(() => import('src/views/management/configurationRepository/List'))
					},{
						exact: true,
						path: [
							'/app/management/config/create'
						],
						component: lazy(() => import('src/views/management/configurationRepository/Create'))
					},{
						exact: true,
						path: [
							'/app/management/config/edit/:confId'
						],
						component: lazy(() => import('src/views/management/configurationRepository/Edit'))
						
					},
					{
						exact: true,
						path: '/app/account',
						component: lazy(() => import('src/views/pages/AccountView'))
					},
					{
						exact: true,
						path: '/app/management/groups',
						component: lazy(() => import('src/views/management/groups/List'))
					},
					{
						exact: true,
						path: '/app/management/groups/create',
						component: lazy(() => import('src/views/management/groups/Create'))
					},
					{
						exact: true,
						path: '/app/management/groups/edit/:groupId',
						component: lazy(() => import('src/views/management/groups/Edit'))
					},
					{
						exact: true,
						path: '/app/management/measures',
						component: lazy(() => import('src/views/management/measures/List'))
					},
					{
						exact: true,
						path: '/app/management/measures/create',
						component: lazy(() => import('src/views/management/measures/Create'))
					},
					{
						exact: true,
						path: '/app/management/measures/edit/:measureId',
						component: lazy(() => import('src/views/management/measures/Edit'))
					},
					{
						exact: true,
						path: '/app/management/groups',
						component: lazy(() => import('src/views/management/groups/List'))
					},
					{
						exact: true,
						path: '/app/management/groups/edit/:groupId',
						component: lazy(() => import('src/views/management/groups/Edit'))
					},
					{
						exact: true,
						path: '/app/management/groups/create',
						component: lazy(() => import('src/views/management/groups/Create'))
					},
					{
						exact: true,
						path: '/app/management/association',
						component: lazy(() => import('src/views/management/association/List'))
					},
					{
						exact: true,
						path: '/app/management/association/create',
						component: lazy(() => import('src/views/management/association/Create'))
					},
					{
						exact: true,
						path: '/app/management/association/edit/:idAssetType/:idGroup',
						component: lazy(() => import('src/views/management/association/Edit'))
					},
					{
						exact: true,
						path: '/app/management/fleet',
						component: lazy(() => import('src/views/management/fleet/List'))
					},
					{
						exact: true,
						path: '/app/management/fleet/create',
						component: lazy(() => import('src/views/management/fleet/Create'))
					},
					{
						exact: true,
						path: '/app/management/fleet/edit/:fleetId',
						component: lazy(() => import('src/views/management/fleet/Edit'))
					},
					{
						exact: true,
						path: '/app/management/fleetAssociation',
						component: lazy(() => import('src/views/management/fleetAssociation/List'))
					},
					{
						exact: true,
						path: '/app/management/fleetAssociation/create',
						component: lazy(() => import('src/views/management/fleetAssociation/Create'))
					},
					{
						exact: true,
						path: '/app/management/fleetAssociation/edit/:idFleetUSR',
						component: lazy(() => import('src/views/management/fleetAssociation/Edit'))
					},
					{
						exact: true,
						path: '/app/management/assetType',
						component: lazy(() => import('src/views/management/assetType/List'))
					},
					{
						exact: true,
						path: '/app/management/assetType/create',
						component: lazy(() => import('src/views/management/assetType/Create'))
					},
					{
						exact: true,
						path: '/app/management/assetType/edit/:assetTypeId',
						component: lazy(() => import('src/views/management/assetType/Edit'))
					},
					{
						exact: true,
						path: '/app/management/asset',
						component: lazy(() => import('src/views/management/asset/List'))
					},
					{
						exact: true,
						path: '/app/management/asset/create',
						component: lazy(() => import('src/views/management/asset/Create'))
					},
					{
						exact: true,
						path: '/app/management/asset/edit/:assetId',
						component: lazy(() => import('src/views/management/asset/Edit'))
					},
					{
						exact: true,
						path: '/app/management/userAssociation',
						component: lazy(() => import('src/views/management/userAssociation/List'))
					},
					{
						exact: true,
						path: '/app/management/userAssociation/create',
						component: lazy(() => import('src/views/management/userAssociation/Create'))
					},
					{
						exact: true,
						path: '/app/management/userAssociation/edit/:associationId',
						component: lazy(() => import('src/views/management/userAssociation/Edit'))
					},
					{
						exact: true,
						path: '/app/management/dashboardTemplate',
						component: lazy(() => import('src/views/management/dashboardTemplate/List'))
					},
					{
						exact: true,
						path: '/app/management/dashboardTemplate/create',
						component: lazy(() => import('src/views/management/dashboardTemplate/Create'))
					},
					{
						exact: true,
						path: '/app/management/dashboardTemplate/edit/:dashboardTemplateId',
						component: lazy(() => import('src/views/management/dashboardTemplate/Edit'))
					},
					{
						component: () => <Redirect to="/404" />
					}
				]
			}
		]
	}
];

const renderRoutes = routes =>
	routes ? (
		<Suspense fallback={<LoadingScreen />}>
			<Switch>
				{routes.map((route, i) => {
					const Guard = route.guard || Fragment;
					const Layout = route.layout || Fragment;
					const Component = route.component;

					return (
						<Route
							key={i}
							path={route.path}
							exact={route.exact}
							render={props => (
								<Guard>
									<Layout>
										{route.routes ? (
											renderRoutes(route.routes)
										) : (
											<Component {...props} />
										)}
									</Layout>
								</Guard>
							)}
						/>
					);
				})}
			</Switch>
		</Suspense>
	) : null;

function Routes() {
	return renderRoutes(routesConfig);
}

export default Routes;
