/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
	Avatar,
	Box,
	Divider,
	Drawer,
	Hidden,
	Link,
	List,
	ListSubheader,
	makeStyles,
	Typography
} from '@material-ui/core';
import { FolderOpen as FolderOpenIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/Logo';
import userInfo from 'src/services/userInfo';
import NavItem from './NavItem';
import { TRANSLATE } from 'src/submodules/commons-misc/constant';
import { useTranslation } from 'react-i18next';

function renderNavItems({ items, ...rest }) {
	return (
		<List disablePadding>
			{items.reduce(
				(acc, item) => reduceChildRoutes({ acc, item, ...rest }),
				[]
			)}
		</List>
	);
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
	const key = item.title + depth;
	if (item.items) {
		let total = 0;
		for (let subitem of item.items) {
			if (userInfo.can(subitem.acl)) {
				total++;
			}
		}
		if (total > 0) {
			const open = true;
			if (userInfo.can(item.acl)) {
				acc.push(
					<NavItem
						depth={depth}
						icon={item.icon}
						key={key}
						info={item.info}
						open={Boolean(open)}
						title={item.title}
					>
						{renderNavItems({
							depth: depth + 1,
							pathname,
							items: item.items
						})}
					</NavItem>
				);
			}
		}
	} else {
		if (userInfo.can(item.acl)) {
			acc.push(
				<NavItem
					depth={depth}
					href={item.href}
					icon={item.icon}
					key={key}
					info={item.info}
					title={item.title}
				/>
			);
		}
	}
	return acc;
}

const useStyles = makeStyles(theme => ({
	mobileDrawer: {
		width: 256
	},
	desktopDrawer: {
		width: 256,
		top: 64,
		height: 'calc(100% - 64px)'
	},
	avatar: {
		cursor: 'pointer',
		width: 64,
		height: 64
	},
	header: {
		background: theme.palette.primary.main
	},
	headerTitle: {
		color: theme.palette.primary.contrastText
	},
	logo: {
		maxWidth: 200,
		maxHeight: 60
	}
}));

function NavBar({ openMobile, onMobileClose }) {
	const classes = useStyles();
	const location = useLocation();
	const { user } = useSelector(state => state.account);
	const { t } = useTranslation();
	const navConfig = [
		{
			items: [
				{
					icon: FolderOpenIcon,
					title: t(TRANSLATE.MANAGEMENT),
					acl: 'telemago.management.menu.management',
					items: [
						{
							title: t(TRANSLATE.MEASURE_GROUPS),
							acl: 'telemago.management.menu.groups',
							href: '/app/management/groups'
						},
						{
							title: t(TRANSLATE.TEMPLATE_DASHBOARD),
							acl: 'telemago.management.menu.templateDashboard',
							href: '/app/management/dashboardTemplate'
						}
					]
				},
				{
					icon: FolderOpenIcon,
					title: t(TRANSLATE.FLEETS),
					acl: 'telemago.management.menu.fleets',
					items: [
						{
							title: t(TRANSLATE.FLEETS),
							acl: 'telemago.management.menu.fleet',
							href: '/app/management/fleet'
						},
						{
							title: t(TRANSLATE.FLEET_ASSOCIATION),
							acl: 'telemago.management.menu.fleetAssociation',
							href: '/app/management/fleetAssociation'
						},
						{
							title: t(TRANSLATE.ASSET_TYPES),
							acl: 'telemago.management.menu.assetType',
							href: '/app/management/assetType'
						},
						{
							title: t(TRANSLATE.ASSETS),
							acl: 'telemago.management.menu.asset',
							href: '/app/management/asset'
						},
						{
							title: t(TRANSLATE.USER_ASSOCIATION),
							acl: 'telemago.management.menu.userAssociation',
							href: '/app/management/userAssociation'
						}
					]
				},
				{
					icon: FolderOpenIcon,
					title: t(TRANSLATE.REPOS),
					acl: 'telemago.management.menu.repo',
					items: [
						{
							title: t(TRANSLATE.FIRMWARE_REPO),
							acl: 'telemago.management.menu.firmware',
							href: '/app/management/firmware'
						},
						{
							title: t(TRANSLATE.CONFIGURATION_REPO),
							acl: 'telemago.management.menu.configuration',
							href: '/app/management/config'
						}
					]
				}
			]
		}
	];

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line
	}, [location.pathname]);

	const content = (
		<Box height="100%" display="flex" flexDirection="column">
			<PerfectScrollbar options={{ suppressScrollX: true }}>
				<Hidden lgUp>
					<Box className={classes.header} pt={2}>
						<Box
							p={2}
							display="flex"
							justifyContent="center"
							height={70}
							alignItems="center"
						>
							<RouterLink to="/">
								<Logo size="small" className={classes.logo} />
							</RouterLink>
						</Box>
						<Box p={2}>
							<Box display="flex" justifyContent="center">
								<Avatar
									alt="User"
									className={classes.avatar}
									src={user?.avatar}
								/>
							</Box>
							<Box mt={2} textAlign="center">
								<Typography variant="h5" className={classes.headerTitle}>
									{`${user?.firstName} ${user?.lastName}`}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Hidden>
				<Hidden lgDown>
					<Box p={2}>
						<Box display="flex" justifyContent="center">
							<RouterLink to="/app/account">
								<Avatar
									alt="User"
									className={classes.avatar}
									src={user?.avatar}
								/>
							</RouterLink>
						</Box>
						<Box mt={2} textAlign="center">
							<Link
								component={RouterLink}
								to="/app/account"
								variant="h5"
								color="textPrimary"
								underline="none"
							>
								{`${user?.name}`}
							</Link>
						</Box>
					</Box>
				</Hidden>
				<Divider />
				<Box p={2}>
					{navConfig.map(config =>
						userInfo.can(config.acl) ? (
							<List
								key={config.subheader}
								subheader={
									<ListSubheader disableGutters disableSticky>
										{config.subheader}
									</ListSubheader>
								}
							>
								{renderNavItems({
									items: config.items,
									pathname: location.pathname
								})}
							</List>
						) : null
					)}
				</Box>
			</PerfectScrollbar>
		</Box>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					classes={{ paper: classes.mobileDrawer }}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer
					anchor="left"
					classes={{ paper: classes.desktopDrawer }}
					open
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
}

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool
};

export default NavBar;
